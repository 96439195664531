const tools = {
    doOnWindowResizeList: [],
    doOnScrollEndList: [],
    doOnScrollList: [],
    windowScrolListenerInitialised: false,
    windowResizeListenerInitialised: false,

    freezeLazy: false,


    isMobile() {return this.device.isSmall()},

    ajxGet(url, options) {
        let req = null;
        if (url) {
            req = new XMLHttpRequest();
            req.onreadystatechange = function () {
                if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                    if (options.fallback) {
                        options.fallback(req.response);
                    }
                }
            };

            if (options.formData && options.formData instanceof FormData) {

                url += url.indexOf('?') === -1 ? '?' : '&';
                for (let entry of formData.entries()) {
                    url += entry[0] + '=' + entry[1] + '&';
                }
                url = url.substr(0, url.length - 1);
            }

            if (options.replaceHistory) {
                tools.replaceHistoryState(url);
            }

            req.responseType = options.responseType || 'text';
            req.open('GET', url, true);
            req.send(null);
        } else {
            options.fallback({error: 'url not provided'})
        }
        return req;
    },

    each(list, fn) {
        if (list && fn) {
            for (let i = 0; i < list.length; i++) {
                fn(list[i], i);
            }
        }
    },

    getCurrentURL(excludeParams) {
        let url = window.location.href;
        if (excludeParams) {
            let i = url.indexOf("?");
            if (i > -1) {
                url = url.substring(0, i);
            }
            let j = url.indexOf("#");
            if (j > -1) {
                url = url.substring(0, j);
            }
        }
        return url;
    },

    getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    addParam(url, param, value) {
        let cleanedUrl = tools.removeParam(url, param);
        return cleanedUrl + (cleanedUrl.indexOf('?') !== -1 ? '&' : '?') + param + '=' + value;
    },

    removeParam(url, paramName) {
        let modifiedUrl = url;

        if (url.indexOf('?') !== -1) {
            let urlArray = url.split('?');
            modifiedUrl = urlArray[0] + tools.removeParamFromQueryPart(urlArray[1], paramName);
        }

        return modifiedUrl;
    },

    removeParamFromQueryPart(urlQuery, param) {
        if (urlQuery.indexOf('?') === 0) {
            urlQuery = urlQuery.substr(1);
        }
        let params = urlQuery.split("&");
        return params.reduce((c, cur) => {
            if (cur.indexOf(param) !== 0) {
                c += (c.length > 0 ? '&' : '?') + cur;
            }
            return c;
        }, '');
    },

    isEditMode() {
        return document.body.classList.contains('editmode')
    },

    scrollTo(elt, destination, duration = 300, fb) {
        tools.freezeLazy = true;
        let start, distance, timeStart;
        function proceed() {
            start = elt === window ? (window.pageYOffset || window.scrollY) : elt.scrollTop;
            distance = destination - start;
            timeStart = null;
            requestAnimationFrame(loop);
        }

        function loop(time) {
            if (!timeStart) {
                timeStart = time;
            }

            let timeElapsed = time - timeStart;

            if (elt === window) {
                window.scrollTo(0, ease(timeElapsed, start, distance, duration))
            } else {
                elt.scrollTop = ease(timeElapsed, start, distance, duration);
            }


            if (timeElapsed < duration) {
                requestAnimationFrame(loop);
            } else {
                if (elt === window) {
                    window.scrollTo(0, destination)
                } else {
                    elt.scrollTop = destination;
                }
                timeStart = false;
                if (fb) {
                    fb(elt);
                }
                tools.freezeLazy = false;
            }
        }

        proceed();

        function ease(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b
        }
    },

    easeTo(start, target, action, duration = 300) {
        if (start && target && action) {
            let distance, timeStart;

            function proceed() {
                distance = target - start;
                timeStart = null;
                requestAnimationFrame(loop);
            }


            function loop(time) {
                if (!timeStart) {
                    timeStart = time;
                }
                let timeElapsed = time - timeStart;
                //todo: result rounding as param
                action(Math.round(ease(timeElapsed, start, distance, duration)));
                if (timeElapsed < duration) {
                    requestAnimationFrame(loop)
                } else {
                    action(target);
                    timeStart = false;
                }
            }

            proceed();

            function ease(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b
            }
        }
    },

    doOnWindowResize(action) {
        if (action && action.fn) {
            this.doOnWindowResizeList.push(action);
            if(!this.windowResizeListenerInitialised) {
                tools.windowResizeListener();
            }
        }
    },

    onWindowResizeActions() {
        tools.each(this.doOnWindowResizeList, action => {
            action.fn(action.arg);
        });
    },

    windowResizeListener() {
        let timeoutFn = null;
        window.onresize = (e) => {
            if(timeoutFn != null) {window.clearTimeout(timeoutFn);}
            timeoutFn = setTimeout(() => {
                tools.onWindowResizeActions();
            }, 100);
        };
        tools.windowResizeListenerInitialised = true;
    },

    doOnScroll(action, duringScroll, scrollEnd) {
        if (action && action.fn) {
            if(duringScroll) {
                this.doOnScrollList.push(action)
            }
            if(scrollEnd) {
                this.doOnScrollEndList.push(action);
            }
            if(!tools.windowScrolListenerInitialised) {
                tools.windowScrollListener();
            }
        }
    },

    onScrollActions() {
        tools.each(this.doOnScrollList, action => {
            action.fn(action.arg)
        });
    },

    onScrollEndActions() {
        tools.each(this.doOnScrollEndList, action => {
            action.fn(action.arg)
        });
    },

    windowScrollListener(fct) {
        let timeoutFn = null;
        window.addEventListener('scroll', (e) => {
            tools.onScrollActions();
            if(timeoutFn != null) {window.clearTimeout(timeoutFn);}
            timeoutFn = setTimeout(() => {
                tools.onScrollEndActions();
            }, 100);
        })
        tools.windowScrolListenerInitialised = true;
    },

    updateHistory(url) {
        tools.updateFHistory(null, null, url)
    },

    updateFHistory(data, title, url,) {
        url = url.replace(/~.*~/, '');
        console.log('updatefhistory', url);
        // url = encodeURI(url);
        window.history.pushState(data, title, url);
    },

    replaceHistoryState(url) {
        url = url.replace(/~.*~/, '');
        // url = encodeURI(url);
        history.replaceState(null, null, url);
    },

    setInterval(fn, delay) {

        let intervalObj = {
            start: Date.now(),
            stop: false,
            delay: delay,
            fn: fn,
            intervalFn: () => {
                Date.now() - intervalObj.start < intervalObj.delay || (intervalObj.start += intervalObj.delay, intervalObj.fn());
                intervalObj.stop || requestAnimationFrame(intervalObj.intervalFn);
            },
            clear: () => intervalObj.stop = true
        }
        requestAnimationFrame(intervalObj.intervalFn)
        return intervalObj;
    },

    detectSafari() {
        return navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    },

    detectIE() {
        var ua = window.navigator.userAgent;

        // Test values; Uncomment to check result …

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    },

    detectEdge () {
        var ua = window.navigator.userAgent;

        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    },

    navBlocked: false,
    navHidden: false,
    navElt: null,

    initNavigation(elt) {
        tools.navElt = elt;
        let scrollPos = 0;
        let lastScrollDown = false;

        const action = {
            arg: {scrollPos: 0, lastScrollDown: false},
            fn:arg => {
                if(!tools.navBlocked) {
                    let currentPos = document.body.getBoundingClientRect().top;
                    arg.lastScrollDown = currentPos <= arg.scrollPos;

                    if (Math.abs(arg.scrollPos - currentPos) > 50) {

                        if (arg.scrollPos < -100 && arg.lastScrollDown ) {
                            tools.hideNav();

                        } else if (!arg.lastScrollDown) {
                            tools.showNav();
                        }
                        arg.scrollPos = currentPos;
                    }
                }
            },
        }

        tools.doOnScroll(action, true, true);
    },

    hideNav(andBlock) {
        tools.navBlocked = false;
        if(!tools.navHidden) {
            tools.navElt.classList.add('mob-hidden');
            tools.navHidden = true;
        }
        if(andBlock) {
            tools.navBlocked = true;
        }
    },

    showNav() {
        tools.navBlocked = false;
        if(tools.navHidden) {
            tools.navElt.classList.remove('mob-hidden');
            tools.navHidden = false;
        }
    },

    freezeNav() {
        tools.navBlocked = true;
    },

    releaseNav() {
        setTimeout(()=>tools.navBlocked = false, 200);
    },

    initNavigationForDesktop(elt) {
        tools.navElt = elt;
        const action = {
            arg: null,
            fn: arg => {
                if((window.pageYOffset || window.scrollY) > 100) {
                    tools.navElt.classList.add('reduced');
                } else {
                    tools.navElt.classList.remove('reduced')
                }
            }
        }

        tools.doOnScroll(action, true, true)
    }

};

module.exports = tools;
