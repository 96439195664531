import UserDevice from "./UserDevice";

const tools = require('./tools');
import Swiper from 'Swiper';
import Lazy from './Lazy';
const app = {
    init() {
        document.documentElement.classList.remove('no-js');
        tools.device = new UserDevice();
        app.nav();
        app.lazyness.init();
        app.sliders();
        app.initPopup();

        //form nofollow link
        const reloadPage = () => window.location.href = tools.getCurrentURL(true);
        tools.each(document.querySelectorAll('.form .error'),
            e => {
                e.addEventListener('click', reloadPage)
            }
        )

    },

    nav() {
        const nav = document.querySelector('#main-nav');
        if(nav) {
            const menuBtn = nav.querySelector('.menu-btn');

            if(menuBtn) {
                menuBtn.addEventListener('click', e => {
                    if(nav.classList.contains('open')) {
                        tools.releaseNav();
                        document.getElementsByTagName('body')[0].style.overflow = "visible";
                        nav.classList.remove('open')
                    } else {
                        tools.freezeNav();
                        document.getElementsByTagName('body')[0].style.overflow = "hidden";
                        nav.classList.add('open');
                    }
                })
            }

            if(tools.isMobile()) {
                tools.initNavigation(nav);
            } else {
                tools.initNavigationForDesktop(nav)
            }
        }
    },

    initPopup() {
        let wasAlreadyOpen = sessionStorage.getItem("wasAlreadyOpen");
        let popupContainer = document.getElementsByClassName('popupContainer')[0];
        let closeButton = document.getElementsByClassName('popupBoxCloseButton')[0];
        let body = document.getElementsByTagName('body')[0];

        if (wasAlreadyOpen == null) {
            wasAlreadyOpen = "false";
        }

        if (wasAlreadyOpen === "false" && popupContainer != null) {
            popupContainer.style.display = 'flex';
            body.style.overflow = "hidden";

        }

        if (closeButton != null) {
            closeButton.addEventListener('click', e => {
                wasAlreadyOpen = "true";
                if (popupContainer != null) {
                    sessionStorage.setItem("wasAlreadyOpen", wasAlreadyOpen);
                }
                popupContainer.style.display = 'none';
                body.style.overflow = "visible";
            })
        }
    },

    lazyness: {
        init() {
            app.lazyness.header();
            app.lazyness.images();
        },

        header() {
            const headerElt = document.getElementById('page-header');
            if(headerElt && headerElt.dataset.lazybg) {
                const fn = (elt) => {
                    const imgSrc = headerElt.dataset.lazybg;
                    if(imgSrc) {
                        const img = document.createElement('img');
                        img.onload = () => {
                            elt.style.backgroundImage = 'url("'+imgSrc+'")';
                        }
                        img.src = imgSrc;
                    }
                }
                new Lazy({targets: headerElt, loadFn: fn})
            }
        },

        images() {
            new Lazy({targets: '.lazy-image'})
        }
    },

    sliders() {
        const sliders = document.querySelectorAll('.swiper-container');

        tools.each(sliders, s => {
            const p = s.querySelector('.pagination');
            const slider = new Swiper(s, {
                loop: true,
                simulateTouch: true,
                preloadImages: false,
                slidesPerView: 1,
                lazy: {loadPrevNext: true},
                spaceBetween: 0,
                pagination: {
                    el: '.pagination',
                    type: 'bullets',
                    clickable: true
                },

            });

        })
    }
};


window.sukoaUtils = {
    dcmadr: function (nnnn) {
        var a = "";
        for (var i = 0; i < nnnn.length; i++) {
            if (i % 3 === 0) {
                a += String.fromCharCode(nnnn.substr(i, 3));
            }
        }
        location.href = (a);
    }
};


document.addEventListener('DOMContentLoaded', () => {
    app.init();
});


