const tools = require('./tools');

class Lazy {

    constructor(options = {
        targets: null,
        loadFn: null,
        threshold: 0.01,
        rootMargin: {top: 0, right: 0, left: 0, bottom: 0},
        persist: false
    }) {
        this.elementList = [];
        if(typeof options.targets === "string") {
            const targets = document.querySelectorAll(options.targets);
            if(targets.length > 0) {
                tools.each(targets, t => this.elementList.push(t));
            }
        } else {
            if(typeof options.targets.length === typeof undefined) this.elementList.push(options.targets);
            else tools.each(options.targets, elt => this.elementList.push(elt));
        }

        this.loadFn = options.loadFn || null;
        this.threshold = options.threshold || 0.01;
        this.rootMargin = options.rootMargin || {top: 0, right: 0, left: 0, bottom: 0};
        this.persist = options.persist;

        this.intersectionObserver = null;

        this.init();
    }

    init() {
        if(this.loadFn === null) {
            this.persist = false;
            this.loadFn = (elt) => {
                const imgSrc = elt.getAttribute('data-lazy');

                if(imgSrc) {
                    const img = document.createElement('img');
                    img.onload = () => {
                        elt.src = imgSrc;
                    }

                    img.src = imgSrc;
                }
            }
        }

        if (typeof IntersectionObserver !== typeof undefined) {
            this.proceedWithObserver();
        } else {
            const fn = (lazy) => {
                if(this.elementList.length > 0) {
                    let loadedIndex = []
                    tools.each(lazy.elementList, (elt, i) => {
                        const topLimit = elt.getBoundingClientRect().top + elt.offsetHeight * this.threshold;
                        const bottomLimit = elt.getBoundingClientRect().bottom + elt.offsetHeight * this.threshold;

                        if(topLimit < window.innerHeight + this.rootMargin.top // to be tested
                            && bottomLimit > this.rootMargin.bottom) {
                            lazy.execLoad({elt: elt, index: i});
                            loadedIndex.push(i);
                        }
                    })
                    if(!this.persist && loadedIndex.length > 0) {
                        this.elementList = this.elementList.reduce((a,b,i) => {
                            if(loadedIndex.indexOf(i) === -1) {
                                a.push(b);
                            }
                            return a;
                        }, [])
                    }
                }
            }
            fn(this);
            tools.doOnScroll({fn: fn, arg: this}, true, true);
        }
    }

    execLoad(options={elt:null, index:-1, observer:null}) {

        if(this.persist || !options.elt.classList.contains('lazy-loaded')) {
            this.loadFn(options.elt);
            options.elt.classList.add('lazy-loaded')

            if(!this.persist) {
                if(options.index !== -1) {
                    this.elementList = this.elementList.splice(options.index, 1);
                }
                if(options.observer) {
                    options.observer.unobserve(options.elt);
                }
            }
        }
    }

    proceedWithObserver() {

        const callback = (entries, observer)  => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    this.execLoad({elt: entry.target, observer: observer})
                }
            })
        };

        this.intersectionObserver = new IntersectionObserver(callback, {threshold: this.threshold, rootMargin: this.getRootMarginString()});

        tools.each(this.elementList, elt => this.intersectionObserver.observe(elt));
    }

    getRootMarginString() {
        return this.rootMargin.top + 'px ' + this.rootMargin.right + 'px ' + this.rootMargin.bottom + 'px ' + this.rootMargin.left + 'px ';
    }
}

export default Lazy;
